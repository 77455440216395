<template>
  <div id="app" class="text-grey" :class="{ 'search-open': showSearch }">
    <div
      class="flex bg-basebackground"
      v-if="preferencesFetched"
      :class="{ signin: $route.name == 'signin' }"
    >
      <router-view
        name="navigation"
        class="z-50 lg:h-screen fixed h-10 w-screen lg:w-12"
        @toggleSearch="onToggleSearch"
      ></router-view>
      <div class="flex flex-grow min-w-0 mt-10 lg:mt-0">
        <transition name="switch" mode="out-in">
          <router-view name="content" v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
        </transition>
      </div>
    </div>
    <search
      v-show="showSearch"
      :showed="showSearch"
      @close="onToggleSearch"
    ></search>
    <blocker v-if="blockScreen"></blocker>
  </div>
</template>

<script>
// Please sign in again
// You were signed out of your account. Please press ‘Reload’ to sign in to the AWS Management Console again.
import '@/assets/styles/index.css';
import '@/assets/scripts/meetric.js';

import { startUserSession } from '@/components/Utils';
import Search from '@/components/Search';
import Blocker from '@/components/Blocker';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@/../tailwind.config.js';
import { debounce } from 'debounce';
import { BroadcastChannel } from 'broadcast-channel';

export default {
  components: {
    Search,
    Blocker,
  },
  data() {
    return {
      preferencesFetched: false,
      showSearch: false,
      // reconnectTimeout: null,
      // reconnectAttempts: 5,
      mobileBreakpoint: 0,
      windowInnerWidth: 0,
      debouncedResize: null,
      bc: null, // broadcast channel
      blockScreen: false,
    };
  },

  computed: {},
  watch: {
    $route(r) {
      this.$notification('');
      if (r.name == 'signout' && r.params?.broadcast) {
        this.bc?.postMessage({ action: 'signout' });
      }
    },
  },
  mounted() {
    startUserSession(this.$gAuth.basicProfile).then(() => {
      this.preferencesFetched = true;
      // this.handleVisChange();
    });

    window.addEventListener('resize', this.handleResize);
    const fullConfig = resolveConfig(tailwindConfig);
    this.mobileBreakpoint = parseInt(
      fullConfig.theme.screens.lg.replace('px', ''),
      10
    );
    this.handleResize();

    this.bc = new BroadcastChannel('meetric');
    this.bc.onmessage = (msg) => {
      if (!this.$gAuth.isAuthorized) return;
      if (
        msg?.action == 'signout' &&
        !['signin', 'signout'].includes(this.$route.name)
      ) {
        this.blockScreen = true;
      }
    };
  },
  methods: {
    onToggleSearch() {
      this.showSearch = !this.showSearch;
    },
    handleResize() {
      this.debouncedResize?.clear();

      this.windowInnerWidth = window.innerWidth;
      let result = false;
      if (this.windowInnerWidth < this.mobileBreakpoint) result = true;

      this.debouncedResize = debounce(() => this.setIsMobile(result), 250);
      this.debouncedResize();
    },
    setIsMobile(value) {
      this.$store.commit('saveIsMobile', value);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
      this.bc?.close();
    },
  },
};
</script>

<style>
body {
  @apply text-base;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>

<style scoped>
.switch-enter-active {
  transition: transform 0.2s ease-out;
}

.switch-enter {
  transform: translateX(+100%);
}
</style>
